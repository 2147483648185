.busy-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.busy-content {
  background-color: #eee;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.busy-content p {
  margin: 10px 0;
}

.title-row {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
  padding: 0 8px;
}

.asset-row {
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  padding: 0 8px;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
}
.flex-container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 800px) {
  .asset-row {
    flex-direction: column;
  }
}
.asset-details {
  flex: 1;
  flex-grow: 1;
  word-break: break-all;
  margin: 0;
}

.tagline {
  margin-left: -320px !important;
  margin-top: 30px !important;
}

.asset-list {
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  padding: 0 10px 10px 10px;
}

.asset-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.3s, border-left 0.3s;
}

.account-container {
  background-color: #eee;
  border-radius: 5px;
  padding: 10px;
}

.create-will-container {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  margin-top: 10px;
}

.asset-locked {
  background-color: #eee;
  border-left: 3px solid #999;
}

.asset-item[title] {
  cursor: default;
}

.asset-item .asset-disabled {
  cursor: help;
}
.asset-item input[type="checkbox"]:disabled + .asset-details {
  color: #646060;
}

.asset-item:hover {
  background-color: rgba(81, 45, 168, 0.25);
}

.asset-locked:hover {
  background-color: #e65f5f;
}

.asset-checkbox {
  width: 30px;
  text-align: right;
  margin-right: 0;
  margin-left: 0;
}

.asset-index {
  margin-right: 10px;
  min-width: 20px;
}

body {
  font-family: Arial, sans-serif;
  background-color: #333;
  margin: 0;
  padding: 20px;
}

.App {
  max-width: 640px;
  margin: auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
}

.header{
  text-align: center;
  margin-bottom: 20px;
  color: #000000;
  font-size: 16px;
}

h1 {
  margin: 0;
}

h3 {
  color: #333;
  margin: 5px 0;
}
h4 {
  color: #333;
  margin: 5px 0 5px 5px;
}
input {
  width: calc(100% - 200px);
  padding: 10px;
  margin: 10px 0 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #512da8;
  color: #eee;
  border: none;
  padding: 10px 15px;
  margin: 5px 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.action {
  width: 100px;
  margin: 5px 10px 0 0;
}

.refresh-button button {
  padding: 0 9px 5px 9px;
  background-color: #512da8;
  color: #eee;
  border: none;
  border-radius: 5px;
  font-size: 30px;
}

.refresh-button :hover {
  background-color: #1a1f2e;
}

button:hover {
  background-color: #512da8;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background: #eee;
  margin:  0;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

strong {
  color: #555;
}

.network-wallet-container {
  align-items: center;
  display: flex;
  justify-content: right;
  margin-top: 0;
  padding-right: 5px;
  margin-bottom: 10px;
}

.network-dropdown {
  margin-top: 0px;
}

.wallet-button {
  font-size: 14px;
  padding: 5px 10px;
}

.wallet-button .wallet-dropdown {
  max-height: 200px;
  overflow-y: auto;
}

.no-assets-message {
  color: #cb4c4e;
  margin-left: 0px;
  font-style: italic; 
  font-size: 15px;
  padding: 10px;
}

.wills-container {
  overflow-y: auto;
  margin-top: 20px;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 0 20px;
  background-color: #eee;
}

li.will-item {
  margin: 10px 0;
  background-color: #fff;
  padding: 10px;
}

.will-item li {
  background-color: transparent;
}

.faq-section {
  margin-top: 20px;
  padding: 20px;
  background-color: #eee;
  border-radius: 5px;
}

.faq-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.faq-card:hover {
  transform: scale(1.02);
}

.faq-card p {
  margin: 5px 0 0 0;
  color: #333;
}

.faq-card div {
  font-weight: bold;
  color: #512da8;
}

.footer {
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  background-color: #333;
  color: #eee;
  border-radius: 5px;
}

.footer p {
  font-size: 12px;
  margin: 0;
}

.social-icons img {
  width: 24px;
  margin: 0 10px;
}

.error-message {
  color: #cb4c4e;
  font-weight: bold;
  margin: 10px 0;
}

.error {
  color: #cb4c4e;
  font-size: 0.8em;
  margin-bottom: 4px;
}

.wallet-adapter-button {
  height: 40px !important;
  line-height: 40px !important;
}
.wallet-adapter-modal-button-close {
  width: 40px !important;

}
.wallet-adapter-dropdown-list-item {
  background-color: #512da8;
  color: #eee;
}

.wallet-adapter-dropdown-list-item:hover {
  background-color: rgba(81, 45, 168, 0.1);
}

.wallet-adapter-modal-list {
  background-color: transparent !important;
}

.wallet-adapter-modal-list li {
  background-color: inherit;
  color: #333;
}

.wallet-adapter-dropdown-list-item {
  background-color: transparent !important;
  color: #eee;
}

.transaction-status {
  color: #cb4c4e;
  margin-top: 10px;
}

.creating-will-button {
  background-color: orange;
  color: #eee;
  cursor: not-allowed;
}

.transaction-status {
  margin-top: 10px;
}

.success {
  color: green;
}

.error {
  color: #cb4c4e;
}

.logo {
  width: 200px;
  height: auto;
  display: flex;
  margin-top: 5px;
}

.page-title {
  margin: 20px 0;
}

@media (max-width: 767px) {
  .App {
    font-size: 14px;
    padding: 10px;
  }
  input {
    width: calc(100% - 20px);
    margin-left: 0
  }
  .page-title{
    margin-top: 0 !important;
  }

  .tagline {
    margin-left: 0px !important;
    margin-top: 10px !important;
  }
  body {
    padding: 0;
  }
  .App {
    padding-top: 20px;
  }
}
